


.preferences{ border-radius: 4px !important;}





/* checkboxes.css */

.checks_all label {
  color: var(--color-tertiary-grey, #646978);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.checks_all {
  display: block;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 18px; /* Space between checkboxes */
}

.custom-checkbox input[type="checkbox"] {
  /* Remove default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Set the size of checkbox */
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background: #fff url('../../../public/assets/uncheck.svg') no-repeat center center;
 
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"]:checked {
  background-image: url('../../../public/assets/checked.svg');
}

/* checkboxes.css */