.overall_location h2{ text-align: center; font-weight: 700; font-size: 36px;}
.viewbylocatioon{ border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 10px 31px 0px rgba(7, 152, 255, 0.09); padding: 50px; margin-top: 100px;}

    .prop_btn {
        border-radius: 50px;
        background: #F0F5FF;
        padding: 8px 16px;
        display: block;
        border: none;
        font-size: 14px;
        font-weight: 400;
        margin-top: -53px;
        margin-left: 19px;
        z-index: 9999;
        position: relative;
    }

    .single_prop_listing img{ }
    .single_prop_listing{ width: 23%; position: relative;}

.location_prop_listing{ justify-content: space-between;}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    font-size: 14px;
    width: 64%;
    text-align: center;
    margin-left: 53px;
    top: 24px;
    z-index: 9999; box-shadow: 0px 10px 31px 0px rgba(7, 152, 255, 0.09);
}
    .single_prop_listing:hover .overlay {
        opacity: 1; 
    }
    
    .overlay a {
        
        text-decoration: none; 
    }
    
  
    .overlay {
        display: block;
        padding-top: 18px;
        height: 90%;
    }
    .overlay p {
        color: #4b4b4b;
        line-height: 11px;
       
    }
    .overlay a{ color: #212121;}

    .all_locations {
        text-align: right;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 30px;
        font-family: 'Roboto', sans-serif !important;
        font-size: 16px;
        font-weight: 500 !important;
        line-height: 150%;
        display: block;
        color: #4b4b4b;
    }