.why-wovnn{ background: url(./../../../public/assets/why.png); min-height: 961px; width: 100%; background-size: cover; margin-top: 100px; padding: 110px 0px;}
.left_why{ width: 44%; position: relative; }
.left_why h2 {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #fff;
    text-align: left;
    padding-right: 40px;
}

.left_why::after {
    background: #fff;
    height: 1px;
    width: 100px;
    content: "";
    position: absolute;
    top: 210px;
}


.right_why{ width: 44%;}
.overall_why{ align-items: center; justify-content: space-between;}

.right_why{}
.right_why h3{ color: var(--color-white-pure, #FFF);
   
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;}
.right_why h4{ color: var(--color-white-pure, #FFF);
    
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;}
    .right_why p {
        color: var(--color-white-pure, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 24px;
    }

    .mt-30{ margin-top: 150px;}
    .top_dn {
        margin-top: 170px;
    }


    .top_doen {
        margin-top: 147px;
    }
    

    .mmt {
        margin-top: -50px;
    }

    .inquery{ border-radius: 10px; padding: 30px 30px;
        background: var(--color-white-pure, #FFF);}


    .inquery h3{ color: var(--color-secondary-dark, #141928);
           
            margin-bottom: 50px;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;}

.inq_form .form-label{ color: var(--color-secondary-dark, #141928);
                
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
            }


 .inq_form .form-control,  .inq_form select{ height: 42px;
                border: 1px solid #F0F5FF;
                box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.25);
                color: #646978;
                font-size: 14px;
                }
 .custom_agreement{ color: var(--color-secondary-dark, #141928);
                    font-family: 'Roboto', sans-serif !important;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 25px; }
  
.custom_btn{ border-radius: 4px;
    background: #22A9E0;
    box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50); padding: 13px 64.405px 13px 64.595px;
    flex-direction: column;
    justify-content: center;
    align-items: center; font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; color: #fff; border: none; margin-top: 30px;}
    .tpdn{ margin-top: 50px;}

    .marg_top{ margin-top:32px;}