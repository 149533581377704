.all_location_only {
    flex-wrap: wrap;

}

.overall_location{ padding: 70px 0px;}

.overall_location h1{ color: var(--color-secondary-dark, #141928);
    text-align: center;
    
   
    font-size:56px;
   
    font-weight: 700; margin-bottom: 80px;
   }

   .all_location_only .single_prop_listing {
    width: 23%;
    position: relative;
    margin-top: 50px;
}



@media (min-width: 768px) and (max-width:1199px) {
    .new_form {
        margin-top: 50px;
    }
    .overall_location h1 {
       
        margin-bottom: 20px;
    }
    .all_location_only .single_prop_listing {
        width: 32%; margin-top: 50px;
       
    }
    .overall_location {
        padding: 40px 0px;
    }
    .overlay {
        height: 250px;
        top: 13px;
        width: 76%;
        left: -17px;
    }
    .single_prop_listing img {
        width: 100%;
        height: 300px;
    }

}


@media (min-width: 541px) and (max-width:767px) {
    
    .overall_location h1 {
       
        margin-bottom: 20px;
    }
    .all_location_only .single_prop_listing {
        width: 48%; margin-top: 10px;
       
    }
    .overall_location {
        padding: 40px 0px;
    }


}



@media (min-width: 320px) and (max-width:540px) {
    .new_form {
        margin-top: -40px;
    }
    .overall_location h1 {
       
        margin-bottom: 20px;
    }
    .all_location_only .single_prop_listing {
        width: 100%; margin-top: 10px;
       
    }
    .overall_location {
        padding: 40px 0px;
    }


}