@media (min-width: 320px) and (max-width: 991px) { 
    .sticky{ display: none;}


}
.full_search_view .select_custom{ background-color: #fff !important;}

.full_search_view .lifestyledrop {
  
    left: -216%;
    
}

.last_entrty{ width: 15% !important;}

@media (min-width: 1367px) and (max-width: 1850px) {  .search-property .container-fluid {
    width: 100% !important;
} 
.search-property .form-control, .search-property .form-select {
       
    font-size: 13px !important;
    
}
.search-property .sel_text {
    font-size: 13px;
}

.single_entry {
    width: 13.5% !important;
}
.last_entrty {
    width: 18% !important;
}

}


@media (min-width: 1280px) and (max-width: 1366px) { 

    .search-property .container-fluid {
        width: 100% !important;
    }
    .search-property .form-control, .search-property .form-select {
       
        font-size: 13px !important;
        
    }
    .search-property .sel_text {
        font-size: 13px;
    }

    .search-property .input-group-text {
       
        padding: 0px 10px;
       
    }
    .single_entry {
        width: 24% !important;
    }
    .last_entrty {
        width: 50% !important;
    }
    .full_search_view .lifestyledrop {
        left: 0%;
    }

}


@media (min-width: 993px) and (max-width: 1279px) { 

    .single_entry {
        width: 24% !important;
    }
    .last_entrty{  width: 24% !important;}
}


@media (min-width: 320px) and (max-width: 992px) { 

    .single_entry {
        width: 100% !important;
    }
    .last_entrty {
        width: 100% !important;
    }
   

    .topp{ margin-bottom: 15px}
    .full_search_view .lifestyledrop {
        left: 0%;
    }

}

.search_headings h1{ color: var(--color-secondary-dark, #141928);

   
    font-size: 24px;
   
    font-weight: 700 !important;
   }
   .crow{ margin-bottom: 20px;}