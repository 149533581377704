

.lifestyledrop {
  border-radius: 0px 0px 16px 16px;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(20, 25, 40, 0.20);
  width: 795px;
  padding: 27px 50px;
}
.budget_rad {
  display: flex;
   align-items: inherit !important; 
  gap: 10px;
   margin-right: 0px !important;  justify-content: space-between;
}


.left_lifestyle {
  width: 47%;
}
.right_lifestyle{ width: 48%;}
.single_search_area {
  position: relative;
  margin-bottom: 11px;
}
.sear_icon {
  position: absolute;
  top: 9px;
  right: 14px;
}

.single_search_area .form-control {
  border: 2px solid #F0F5FF;
  min-height: 42px;
  border-radius: 0px !important;
  color: var(--color-tertiary-grey-light, #8d8d8d) !important;
  font-size: 15px;
  font-weight: 400;
  padding-left: 10px;
}
.lifestyledrop h3 {
  color: #000 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-bottom: 7px !important;
  text-transform: uppercase;
}

.lifestyledrop{ justify-content: space-between; flex-wrap: wrap;}


.distancetime { justify-content: space-between; flex-wrap: wrap;}
.left_time, .right_time {
  justify-content: space-between;
  width: 48%;
}

.single_full_search  { justify-content: space-between; flex-wrap: wrap;}
.miles_time{ margin-top: 10px;}

.left_time .link{ color: var(--color-tertiary-grey-light, #B4B9C8);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 208.333% */
  text-transform: uppercase;}


  .miles_time .thumb {
    height: 18px;
    width: 72px;
    text-align: center;
    background-color: #22A9E0;
    color: white;
    line-height: 18px;
    border-radius: 20px;
    cursor: grab;
    top: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
}

.left_time .active {
  color: #141928 !important; font-weight: 600;
}

.miles_time .track-0 {
  background: #22A9E0;
  height: 5px;
  border-radius: 100px;
}



.distancetime {
 
  margin-top: -5px;
  
}

.right_time.d-flex {
  /* margin-top: 3px; */
  position: relative;
  top: 0px;
}

.icons_row span {
  font-size: 13px !important;
}


.list_icon {
  background: #fff;
  height: 23px;
  width: 23px;
  text-align: center;
  border-radius: 100px;
  padding: 0px !important;
}


.list_icon .active {
  background: #fff;
  
}


.list_icon:hover {
  background: #F0F5FF;
  
}

/* LifeStyleSearchDropdown.css */
.list_icon.active {
  background-color: #F0F5FF; 
}







.single_search_area .form-control::placeholder {
  color: var(--color-tertiary-grey-light, #B4B9C8)
}

.single_search_area .form-control::-webkit-input-placeholder {
  color: var(--color-tertiary-grey-light, #B4B9C8)
}

.single_search_area .form-control::-moz-placeholder {
  color: var(--color-tertiary-grey-light, #B4B9C8)
}

.single_search_area .form-control:-ms-input-placeholder {
  color: var(--color-tertiary-grey-light, #B4B9C8)
}

.single_search_area .form-control:-ms-input-placeholder {
  color: var(--color-tertiary-grey-light, #B4B9C8)
}

.single_search_area .form-control::-ms-input-placeholder {
  color: var(--color-tertiary-grey-light, #B4B9C8)
}




/* suggestion-page-sidebar-css */

.single_suggestion {
  border-bottom: 1px solid #F7F8F9;
  padding-bottom: 20px;
  padding-top: 20px;
}
.suggestions_sidebar {
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  width: 382px;
  padding: 25px;
  z-index: 999999;
  height: 100vh;
  overflow-y: auto;
}
.suggestions_sidebar .single_search_area {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.main_suggestion{ justify-content: space-between;}
.phone_sugges{ margin-left: 20px;}

.single_suggestion h3{ color: var(--color-secondary-dark-light, #505564);

  
  font-size: 18px;
 
  font-weight: 700;
 }
.single_suggestion p{ color: var(--color-tertiary-grey-mid, #787D8C); 

 
  font-size: 12px;
  font-weight: 400;
  line-height: 150%; margin-top: -6px;}

  .left_sugg{ color: var(--color-secondary-dark-light, #505564);

  
    font-size: 14px;
   
    font-weight: 400;
    line-height: 150%; }

    .right_sugg{ color: var(--color-tertiary-grey-mid, #787D8C);

     
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;}

    .span_count{ color: var(--color-tertiary-grey-light, #B4B9C8) !important;
    }

    .suggestions_sidebar .sear_icon {
      position: absolute;
      top: 9px;
      right: 44px;
  }

  .suggestions_sidebar .single_search_area .form-control {
   
    padding-right: 60px;
}




.cross_icon {
  position: absolute;
  right: -30px;
}