.cta-two {
    background-color: #505564;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cta-content {
  
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    border-radius: 8px; 
}

.cta-left {
    flex: 1 1;
    padding-right: 20px;
    padding: 40px;
}
.cta-left h2{ color: var(--color-white-pure, #FFF);
  
   
    font-size: 48px;
    font-style: normal; margin-bottom: 30px;
   
    }

.cta-right {
    
    padding-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cta-right img{ border-radius: 20px;}

.cta-two .input-group {
    display: flex;
    align-items: center;
    gap: 0px;
}

.cta-two input {
    flex: 1;
    padding: 10px;
    border-radius: 4px; height: 42px; border: none;   color: var(--color-tertiary-grey-mid, #787D8C);
    
    
    font-size: 14px;
    
    font-weight: 400;
    line-height: 150%; 
}

.cta-two button {
    padding: 10px 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;background: #22A9E0 ; color: #fff; height: 42px;  ;
    text-align: center;
  
    font-size: 16px;
  
    font-weight: 500;
    line-height: 16px; 
}
