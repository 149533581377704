

.calculation_zone {
    background: #F0F5FF;
    padding: 20px;
    border-radius: 5px;
    margin-top: 36px;
}
.bottom_calc {
    background: #fff;
    padding: 20px;
    margin-top: 15px;
    padding-top: 10px;
    padding-bottom: 32px;     border-radius: 5px;
}
.bottom_calc .font-weight-bold { color: var(--color-secondary-dark, #141928);

    /* Heading 4 */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;}

    .bottom_calc small{ color: var(--color-tertiary-grey-mid, #787D8C);
       
        font-family: Oxygen;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; }


        .mr-2{ margin-right: 20px !important;}
        .mt-6{ margin-top: 40px !important;}

        .calculation_zone label {
            color: var(--color-secondary-dark-light, #505564);
            margin-bottom: 10px;
            font-size: 17px;
            font-weight: 700;
        }
           .top_calc {
            padding: 10px 30px;
        }
        .hmtp {
            margin-top: 25px !important;
        }

        .top_calc .form-control{ box-shadow: 0px 0px 20px 0px rgba(231, 231, 231, 0.25); border-radius: 5px; background: var(--color-white-pure, #FFF); height: 46px; border: none;

        }
        .form-range {
            width: 100%;
            height: 1.5rem;
            padding: 0;
            background-color: transparent;
            appearance: none;
            margin-top: -35px !important;
            position: relative !important;
            top: -18px !important;
        }


        .dpay{ position: relative;}
        .dpay_amount {
            position: absolute;
            right: 0;
            top: 49px;
            right: 24px; color: var(--color-tertiary-grey-mid, #787D8C);
         
         
            font-size: 16px;
          
            font-weight: 400;
            line-height: 150%; 
        }





        /* General styles */
   /*      input[type='range'] {
            appearance: none;
            width: 100%;
            margin: 10px 0;
            background: linear-gradient(to right, #22A9E0 var(--percent, 10%), #fff 0);
            outline: none;
            height: 4px;
        }


input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #22A9E0;
    cursor: pointer;
    margin-top: -4px;  
}
input[type='range']::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 20%;
    background: #000;
    cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: transparent;
}
input[type='range']::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: transparent;
}


input[type='range']::-moz-range-progress {
    background-color: #22A9E0;
}

.form-range {
    background: linear-gradient(to right, blue var(--percent, 0%), #e5e5e5 var(--percent, 0%));
}

 */

        