.homePage-container {
    margin-top: -96px;
}


@media (min-width: 1025px) and (max-width: 1279px) {
    .just_listed .left_property_image img {
        border-radius: 10px;
        height: 223px;
    }
    .properties_listing {
        margin-top: 60px !important;
    }

    .container {
        width: 100% !important;
        max-width: 97% !important;
    }
}



@media (min-width: 768px) and (max-width: 1024px) {

/* search-responsive */

.mtdn {
    margin-top: 17px !important;
}
.mtdn_btn{ margin-bottom: 10px !important;}
.property_drop{ left: 0;}
.property_drop h3{ font-size: 14px !important;}

/* search-responsive */

    


    .container {
        width: 100% !important;
        max-width: 100% !important;
    }

    h2{ font-size: 23px !important; }
    h1{ font-size: 27x !important;}
    p{ font-size: 15px !important;}
    h3{font-size:19px !important}
    h4{ font-size: 17px !important;}
    .overall_nav img{ width: 100px;}
    
    .properties_listing {
        margin-top: 50px !important;
    }
    .property-card{ margin-bottom: 20px;}
    .price {
       
        width: 100%;
    }
    .top_dn {
        margin-top: 20px;
    }
    .slider-caros {
       
        margin-top: 12px;
    }
    .listing_btn {
       
        margin-top: 49px;
    }
    .mutual_btn a {
      
        padding: 10px 20px;
    }
    .viewbylocatioon {
        padding: 10px; padding-top: 30px;
        margin-top: 50px;
    }
    
    .single_prop_listing {
        width: 100%;
        
    }
    .location_prop_listing {
        display: flex!important;
        flex-wrap: wrap;
    }
    .all_locations{ font-size: 14px;}
    .single_prop_listing {
        width: 49%;
        margin-bottom: 36px;
    }
    .cta_block_one {
        width: 100%;
        margin-top: 0px;
        flex-wrap: wrap;
    }
    .cta_blocks.d-flex {
        margin-top: 30px;
    }
    .cta_image img{ width: 50px;}
    .welcome_text {
       
     
        margin-left: 0px;
        
    }
    .welcome_text p{ line-height: 28px !important;}
    .agent_list {
        margin-top: 37px;
    }
    .agent_one {
        width: 49%;
        margin-top: 30px;
    }
    .agent_list h2 {
       
        margin-bottom: 5px;
    }
    .cta_blocks.d-flex {
        flex-wrap: wrap;
    }
    
    .why-wovnn {
        
        margin-top: 100px;
        padding: 0px 0px;
        padding-bottom: 30px;
    }
    .marg_top {
        margin-top: 32px;
    }
    .left_why {
        width: 100%;
       
    }
    .right_why {
        width: 100%;
    }
    .tpdn {
        margin-top: 50px;
        padding-top: 40px;
    }
    .left_why::after {
       display: none;
    }
    .mt-30 {
        margin-top: 0px;
    }
    .just_listed {
        margin-top: 40px;
        margin-bottom: 60px;
    }
    .just_listed .left_property_image {
        width: 100%;
    }
    .bottom_property {
        
        width: 100%;
    }
    .just_listed .property-card {
        display: block !important;
        align-items: inherit !important;
    }
    .overall_why {
        align-items: inherit;
        flex-wrap: wrap;
    }
    .space-type {
        margin-top: -70px;
    }
    .mmt {
        margin-top: 20px;
    }
    .top_doen {
        margin-top: 18px;
        margin-bottom: 29px;
    }
    .image_text::after {
       
        top: 130px;
    }
    .image_blocks { margin-top: 70px;}
    .image_blocks {
        margin-top: 10px;
    }
    .testimonials {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .testimonials_heading p {
        font-size: 14px;
       
        padding: 0px 0px;
    }
    .cta-right{ display: none;}
    .cta-left {
       
        padding: 25px;
    }
    
    .cta-two button {
      
        border-radius: 4px;
        
        margin-top: 0px; border-radius: 4px;
    }
    .cta-two input{ border-radius: 4px;}
    .custom-footer {
       padding: 30px 0px;
        margin-top: 50px;
       
    }
    .footer-address {
       
        line-height: 29px !important;
     
    }
    
    .footer-heading {
        margin-bottom: 23px;
       
        margin-top: 20px;
    }
    .newsletter {
        margin-top: 30px;
    }
    .foot_clients {
        flex-wrap: wrap;
        margin-top: 40px !important;
    }
    .client-box {
        width: 19% !important;
        margin-top: 9px;
    }
    .foot_clients{ flex-wrap: wrap;}
    
    .footer-copyright {
            margin-top: 50px !important;
        margin-left: 0px !important;
    }
    .footer_links { flex-wrap: wrap;}
    .footer_links {
        justify-content: right;
        margin-top: 30px;
        margin-left: 20px;
        text-align: right;
        line-height: 39px;
    }
    .links{ width: 100%;}
    .overall_agents {
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .price-amenities {
       
        flex-wrap: wrap;
    }
    .single_amenities.d-flex {
        margin-left: 0px;
        margin-top: 22px;
    }
    .amenities.d-flex {
        gap: 23px;
    }
    .single_prop_listing img {
        width: 100%;
    }
    .custom_navi { display: none !important;}
    /* mobile-nav */

.mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.mobile-logo img {
    width: 100px;
    height: auto;
}

.mobile-menu-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
    text-align: right;
    position: absolute;
    right: 13px;
    top: 15px;
}

.mobile-menu-content {
    position: fixed;
    top: 70px;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 25px;
}

.mobile-link {
    display: block;
    padding: 10px 15px;
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid #494949; font-size: 14.5px;
}

.mobile-link:hover {
    background-color: rgba(255, 255, 255, 1) !important;
}

.dropdown {
    padding: 10px 15px;
    cursor: pointer; color: #fff; border-bottom: 1px solid #494949; font-size: 14.5px;
}

.dropdown:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.mobile-nav {
    display: block !important;
}
svg.svg-inline--fa.fa-chevron-down {
    position: absolute;
    right: 15px;
    top: 13px;
}
.dropdown .mobile-link:last-child {
    border-bottom: none;
}

.dropdown-content {
    margin-top: 8px;
    background: #2b2b2b;
}

    

}


@media (min-width: 320px) and (max-width: 767px) {


   




    h2{ font-size: 22px !important; }
h1{ font-size: 26px !important;}
p{ font-size: 14px !important;}
h3{font-size:18px !important}
h4{ font-size: 16px !important;}

.overall_nav img{ width: 100px;}

.properties_listing {
    margin-top: 50px !important;
}
.property-card{ margin-bottom: 20px;}
.price {
   
    width: 100%;
}
.top_dn {
    margin-top: 20px;
}
.slider-caros {
   
    margin-top: 12px;
}
.listing_btn {
   
    margin-top: 49px;
}
.mutual_btn a {
  
    padding: 10px 20px;
}
.viewbylocatioon {
    padding: 10px; padding-top: 30px;
    margin-top: 50px;
}

.single_prop_listing {
    width: 100%;
    
}
.location_prop_listing {
    display: flex!important;
    flex-wrap: wrap;
}
.all_locations{ font-size: 14px;}
.single_prop_listing {
    width: 100%;
    margin-bottom: 36px;
}
.cta_block_one {
    width: 100%;
    margin-top: 0px;
    flex-wrap: wrap;
}
.cta_blocks.d-flex {
    margin-top: 30px;
}
.cta_image img{ width: 50px;}
.welcome_text {
   
 
    margin-left: 0px;
    
}
.welcome_text p{ line-height: 28px !important;}
.agent_list {
    margin-top: 37px;
}
.agent_one {
    width: 100%; margin-top: 30px;
  
}
.agent_list h2 {
   
    margin-bottom: 5px;
}
.cta_blocks.d-flex {
    flex-wrap: wrap;
}

.why-wovnn {
    
    margin-top: 100px;
    padding: 0px 0px;
    padding-bottom: 30px;
}
.marg_top {
    margin-top: 0px;
}
.left_why {
    width: 100%;
   
}
.right_why {
    width: 100%;
}
.tpdn {
    margin-top: 50px;
    padding-top: 40px;
}
.left_why::after {
   display: none;
}
.mt-30 {
    margin-top: 0px;
}
.just_listed {
    margin-top: 40px;
    margin-bottom: 60px;
}
.just_listed .left_property_image {
    width: 100%;
}
.bottom_property {
    
    width: 100%;
}
.just_listed .property-card {
    display: block !important;
    align-items: inherit !important;
}
.overall_why {
    align-items: inherit;
    flex-wrap: wrap;
}
.space-type {
    margin-top: -70px;
}
.mmt {
    margin-top: 20px;
}
.top_doen {
    margin-top: 18px;
    margin-bottom: 29px;
}
.image_text::after {
   
    top: 130px;
}
.image_blocks { margin-top: 70px;}
.image_blocks {
    margin-top: 10px;
}
.testimonials {
    margin-top: 0px;
    margin-bottom: 0px;
}
.testimonials_heading p {
    font-size: 14px;
   
    padding: 0px 0px;
}
.cta-right{ display: none;}
.cta-left {
   
    padding: 25px;
}

.cta-two button {
  
    border-radius: 4px;
    
    margin-top: 20px; border-radius: 4px;
}
.cta-two input{ border-radius: 4px;}
.custom-footer {
   padding: 30px 0px;
    margin-top: 50px;
   
}
.footer-address {
   
    line-height: 29px !important;
 
}

.footer-heading {
    margin-bottom: 23px;
   
    margin-top: 20px;
}
.newsletter {
    margin-top: 30px;
}
.foot_clients {
    flex-wrap: wrap;
    margin-top: 40px !important;
}
.client-box {
   
    width: 100% !important;   margin-top: 9px;
    
}
.foot_clients{ flex-wrap: wrap;}

.footer-copyright {
        margin-top: 50px !important;
    margin-left: 0px !important;
}
.footer_links { flex-wrap: wrap;}
.footer_links {
    justify-content: right;
    margin-top: 30px;
    margin-left: 20px;
    text-align: right;
    line-height: 39px;
}
.links{ width: 100%;}
.overall_agents {
    justify-content: space-between;
    flex-wrap: wrap;
}
.price-amenities {
   
    flex-wrap: wrap;
}
.single_amenities.d-flex {
    margin-left: 0px;
    margin-top: 22px;
}
.amenities.d-flex {
    gap: 23px;
}
.single_prop_listing img {
    width: 100%;
}
.custom_navi { display: none !important;}

/* mobile-nav */

.mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.mobile-logo img {
    width: 100px;
    height: auto;
}

.mobile-menu-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
    text-align: right;
    position: absolute;
    right: 13px;
    top: 15px;
}

.mobile-menu-content {
    position: fixed;
    top: 70px;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 25px;
}

.mobile-link {
    display: block;
    padding: 10px 15px;
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid #494949; font-size: 14.5px;
}

.mobile-link:hover {
    background-color: rgba(255, 255, 255, 1) !important;
}

.dropdown {
    padding: 10px 15px;
    cursor: pointer; color: #fff; border-bottom: 1px solid #494949; font-size: 14.5px;
}

.dropdown:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.mobile-nav {
    display: block !important;
}
svg.svg-inline--fa.fa-chevron-down {
    position: absolute;
    right: 15px;
    top: 13px;
}
.dropdown .mobile-link:last-child {
    border-bottom: none;
}

.dropdown-content {
    margin-top: 8px;
    background: #2b2b2b;
}

/* search-responsive */

.mtdn {
    margin-top: 17px !important;
}
.mtdn_btn{ margin-bottom: 10px !important;}
.property_drop {
   
    left: 0;
  
}
.comercial_block {
   
    flex-wrap: wrap;
}
.options {
    width: 100%;
    display: flex;
    flex-wrap: wrap; justify-content: space-between;
}

.property_drop {
    position: absolute;
  padding: 10px;
    min-width: 100%;
   
}
.property_drop h3 {
    font-size: 14px !important;
    margin-bottom: 5px;
}
.option {
    font-size: 12px;
    padding: 3px 0px;
    width: 48%;
    text-align: center; margin: 5px 0px;
}


.left_input_blocks {
   
    width: 100%;
}
.right_input_blocks {
   
    width: 100%;
    margin-top: 10px;
}
.acre_swap {
  
    flex-wrap: wrap;
}
.unique-radio-label {
    margin-right: 8px;
    margin-left: 32px;
}
.unique-radio-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 27px;
    margin-top: 25px;
    margin-bottom: 10px;
}

.dropdown-input {
   
    background-position: 181px 12px !important;
   
}
.select_custom{ font-size: 13px;}
.budget_rad {
   
    margin-top: 15px;
}
.budget_rad span {
  
    top: -1px;
    width: 280px !important;
    left: -2px;
}
.right-radio {
    width: 39%;
}

.custom_search{ margin-top: 25px;}
.dropdown-input {
    background-position: right !important;
   
}
.lifestyledrop {
    width: 100%;
    padding-bottom: 30px;
}
.left_lifestyle {
    width: 100%; 
}
.right_lifestyle {
    width: 100%; 
}

.single_search_area .form-control {
   
    font-size: 13px;
   
}
.distancetime {
    margin-top: 0px;
}
.single_search_area {
    position: relative;
    margin-bottom: 7px;
    margin-top: 34px;
}
.left_time .link{ font-size: 11px;}
.right_time {
    justify-content: space-between;
    width: 55;
}
.left_time{ width: 40%;}
.right_time.d-flex {
    /* margin-top: 3px; */
    position: relative;
    top: 2px;
}
.list_icon {
  
    height: 20px;
    width: 20px;
    
    padding: 2px !important;
}
.icons_row span {
    font-size: 11.5px !important;
}

.lifestyledrop h3 {
    font-size: 14px !important;
    margin-bottom: -22px;
}

/* search-responsive */


 
}


.mobile-nav{ display: none;}



/* property-sidebar-form-css */

.property_drop h3 {
    color: var(--color-tertiary-grey, #646978);
    font-family: 'Oxygen', sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
    margin-top: 7px;
}


.property-detail-form .sel_icon{ display: none;}

.property-detail-form .budget_drop {
    right: 0;
    overflow: hidden;
}


