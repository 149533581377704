









  

    .dream_only form.inq_form {
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden !important;
        padding-right: 12px;
    }

    .question p{  color: var(--color-tertiary-grey-mid, #787D8C);

      margin-top: 10px;
        font-size: 14px !important;
       
        font-weight: 400 !important; margin-bottom: 7px;
       
    }




    .bootstrap_textarea .form-control {
        border-radius: 4px !important;
        border: 1px solid var(--color-white-blue, #F0F5FF) !important;
        background: var(--color-white-pure, #FFF) !important;
        box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.25) !important;
        min-height: 122px !important;
        
        color: var(--color-tertiary-grey-mid, #787D8C) !important;
       
        font-size: 14px !important;
        
        font-weight: 400;
        line-height: 150%;  padding: 20px;
      }
      
      .bootstrap_textarea .form-control::placeholder {
        color: var(--color-tertiary-grey-mid, #787D8C);
        opacity: 1;
      }
      .bootstrap_textarea {
        margin-top: 30px;
    }
    .switch{ margin-top: 25px;}
    .switch p{ color: var(--color-tertiary-grey-mid, #787D8C);

       
       
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;}




        .toggle-switch {
            position: relative;
            display: inline-block;
            width: 36px;
            height: 20px;
          }
          
          .toggle-input {
            opacity: 0;
            width: 0;
            height: 0;
          }
          
          .toggle-slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #DEE7EE; 
            transition: .4s;
            border-radius: 10px; 
          }
          
          .toggle-slider:before {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            left: 5px;
            bottom: 4px;
            background-color: white;
            transition: .4s;
            border-radius: 100px;
        }
          
          .toggle-input:checked + .toggle-slider {
            background-color: #22A9E0; 
          }
          
          .toggle-input:checked + .toggle-slider:before {
            transform: translateX(16px); 
          }
          




 















      


/* Checkbox ui */
.question {
    margin-top: 12px !important;
}
   
.custom-radio label {
    display: flex;
    align-items: center; 
    cursor: pointer;  
  }


  .custom-radio {
    color: var(--color-tertiary-grey-mid, #787D8C);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-left: 19px;
}

 
  .custom-radio input[type="radio"] {
    opacity: 0; 
    position: absolute;
  }
  
  
  .custom-radio .checkmark {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #22A9E0;
    border-radius: 50%; 
    margin-right: 5px;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;  margin-left: 5px;
  }
  
  .custom-radio input[type="radio"]:checked + .checkmark {
    background-color: #03A9F4;
    display: inline-block;
    padding: 8px; 
  }
  
  .custom-radio input[type="radio"]:checked + .checkmark:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
  }
  
  .question .custom-radio:nth-child(2) {
    margin-right: 20px; 
  }



  .dream_only .budget_drop {
    min-width: 305px !important;
    left: -160px !important;
    padding-bottom: 10px !important;
}

.dream_only .preferences {
    min-width: 200px !important;
    width: 240px;  left: -87px !important;
}


.dream_only .property_drop {
    position: absolute;
    border-radius: 10px;
    background: var(--color-white-pure, #FFF);
    box-shadow: 0px 8px 10px 0px rgba(20, 25, 40, 0.25);
    min-width: 330px;
    top: 42px;
    left: 0%;
    z-index: 9999;
    padding: 20px;
}