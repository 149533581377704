
.container-fluidic{ width: 100%;}
.cta_text h3{ 
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; color: #fff;}
.cta_text p{ 
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; color: #fff; }

    .two_block{ background: var(--color-tertiary-grey, #646978) !important; padding: 20px;}
    .three_block {
        background: var(--color-tertiary-grey-mid, #787D8C) !important;
        padding: 20px;
    }

    .cta_image {
        margin-right: 13px;
    }
    .cta_text {
        margin-top: 16px;
    }
    .container-fluidic {
        width: 100% !important; margin: 0px !important; padding: 0px !important;
    }
    .cta_block_one {
        background: #505564;
        padding: 20px;
        width: 100%; margin-top: 80px; align-items: center;
    }