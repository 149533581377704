.welcome_image{}
.welcome_image h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}
    .welcome_text p{ 
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; color: var(--color-tertiary-grey-mid, #787D8C);
    }
    .welcome {
        margin-top: 90px;
        margin-bottom: 25px;
    }
    .row_man{ align-items: center;}
    .welcome_text {
        vertical-align: middle;
        margin-top: 30px; margin-left: 30px;
    }