.search-form {
    background: #fff;
    padding: 30px 0px;
    box-shadow: none;
    margin-top: 0px !important; border-radius: 0px; padding-bottom: 10px;
}

.searched_properties{ margin-top: 80px;}

.searched_properties .property-card {
   
    margin-bottom: 24px;
  
}



.search-form {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.3s ease, opacity 0.3s ease; 
}
.sticky {
    position: fixed;
    top: -50px; 
    opacity: 0; 
    width: 100%;
    z-index: 1000;
    background-color: #22A9E0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
    animation: slideDownFadeIn 0.5s forwards 0.1s ease-out;
}

.all_form_flex{ flex-wrap: wrap; justify-content: space-between;}
.single_entry {
    width: 13.8%;
}
.set_block {
    max-height: 800px; /* Adjust this to a height value that suits your design */
    overflow-y: auto;
}

.set_block {
    display: flex;
   
    white-space: nowrap;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #B4B9C8 #B4B9C8; /* For Firefox */
}

::-webkit-scrollbar {
    width: 4px; /* for the width */
    height: 30px !important; /* for the height */
}

::-webkit-scrollbar-thumb {
    background-color: #646978; 
}
::-webkit-scrollbar-track {
    background-color: #B4B9C8;  width: 4px; height: 30px;
}


@keyframes slideDownFadeIn {
    to {
        top: 0;
        opacity: 1;
    }
}

.blocks_counting{ font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; text-align: right; margin-bottom: 20px;}

    .search-form span.input-group-text {
     
        background: #fff !important;
    }


    .sticky .custom_search{ background: #141928 !important; }