

.breads {
    list-style-type: none;
    display: flex;
    padding: 0;
}

.breads li {
    margin: 0 5px; 
}

.breads a {
    color: var(--color-tertiary-grey-mid, #787D8C);
 
   
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
   
    text-decoration: none;
    transition: color 0.3s;


    cursor: pointer;
}

.breads a:hover {
    color: #0056b3; 
}

.arrow {
    margin: 0 5px;
    color: #22A9E0;
    font-size: 12px;
    position: relative;
    top: 4px;
}
.breads li:last-child {
    color: var(--color-secondary-dark, #141928);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    cursor: default;
    margin-top: 2px;
}

.active-button {
    border-radius: 4px !important;
    border: 2px solid var(--color-white-pure, #FFF) !important;
    background: #22A9E0 !important;
    box-shadow: 0px 0px 20px 0px rgba(231, 231, 231, 0.25) !important;
    color: #fff !important;
  }
  



.propert-banner-bg{ background: var(--color-white-blue, #F0F5FF); padding: 50px 0px; padding-bottom: 20px;}

.Property_detailing h2{ color: var(--color-secondary-dark, #141928) !important;
  
    font-size: 24px !important;
  
    font-weight: 700;
   }

   .Property_detailing .location_name{ color: var(--color-secondary-dark-light, #505564);
   
    font-size: 24px;
  
    font-weight: 300;
   }
   .Property_detailing .location_icon {
    font-size: 20px;
    color: #22A9E0;
    margin-top: 6px;
}

.Property_detailing .amenities {
    gap: 22px;
    flex-wrap: wrap;
}
.Property_detailing .single_amenities{ margin-left: 0px;}

.Property_detailing .price {
    color: var(--color-secondary-dark, #141928);
    font-size: 48px;
    font-weight: 700;
    margin-top: 80px; font-family: 'Roboto', sans-serif !important;
}
.avm{ 
    color: var(--color-tertiary-grey-mid, #787D8C);

   
    font-size: 15px;
    font-weight: 400;
    line-height: 150%; 
   
  }
  .stars_rating{ margin-top: 10px;}

  .rate_number, .total_rate{ color: var(--color-tertiary-grey-mid, #787D8C);

   
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */}
    .stars { color: #22A9E0;}
    .rate_number{ margin-right: 5px;}
    .total_rate{ margin-left: 5px;}
    .three_btns{ display: flex; justify-content:center; gap: 50px; margin-top: 30px; margin-bottom: 20px;}

    .btn_blue{ border-radius: 4px;
        background: var(--color-primary-blue, #22A9E0);
        box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50); border: none; padding:16px 26px; color: #fff;   color: var(--color-white-pure, #FFF);
        text-align: center;
        font-family: 'Roboto', sans-serif !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px; /* 93.75% */}



.mt-new{ margin-top: 90px;}
.detail_icon{ color: var(--color-tertiary-grey-mid, #787D8C);
   
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; }

    .mar_lefts{ margin-left: 10px;}
    .marl{ margin-left: 5px;}
    .updated{ margin-top: 5px;}
    .con_pun{ font-size: 20px; color: #22A9E0; margin-top: -2px;}
    .save-listing{ border-radius: 4px; 
        border: 1px solid var(--color-secondary-dark, #141928);
        background: var(--color-white-pure, #FFF);
        box-shadow: 0px 0px 20px 0px rgba(231, 231, 231, 0.25); padding: 8px 16px;color: var(--color-secondary-dark, #141928);
       
        font-size: 12px;
      
        font-weight: 700;
        line-height: 150%; }
        svg.svg-inline--fa.fa-heart.heart-icon {
            margin-left: 6px; font-size: 14px;
        }
        .btn_setting {
            display: flex !important;
            justify-content: end !important;
            margin-top: -45px;
        }
        .Property_detailing {
            padding-right: 6px;
        }


        .clickable:hover {
            cursor: pointer;
            opacity: 0.7;
        }
        .custom-image {
            height: 238px !important;
            object-fit: cover;
            width: 100%;
            border-radius: 5px;
        }

        /* PropertyDetail.css */
.modal-image {
    opacity: 0;
    transition: opacity 0.3s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.modal-image.active {
    opacity: 1;
}

.position-absolute.start-0 {
    left: 10px; /* Adjust as needed */
    z-index: 1000; /* Ensure the icon stays above other elements */
}

button.btn-close {
    position: absolute;
    z-index: 999999;
    right: 10px;
    top: -212px;
    background: #22A9E0;
    border-radius: 500px;
    opacity: 1;
    color: #fff !important;
    width: 24px;  /* Adjust based on your requirements */
    height: 24px; /* Adjust based on your requirements */
    display: flex;
    align-items: center;
    justify-content: center;
}
.settable{ margin-top: 20px;}
svg.svg-inline--fa.fa-chevron-left.position-absolute.start-0.mt-5.clickable {
    background: #22A9E0;
    padding: 13px 10px; opacity: 1;
    color: #fff;top: -50px
}
svg.svg-inline--fa.fa-chevron-right.position-absolute.end-0.mt-5.clickable{
    background: #22A9E0;
    padding: 13px 10px;
    color: #fff; opacity: 1; top: -50px;
}
.prop_detail_form{ padding: 50px 0px; margin-top: 80px;}
.overall_property_detail h2{ color: var(--color-secondary-dark, #141928);
          font-size: 24px !important;
     font-weight: 700 !important;
    }
    .overall_property_detail p{ color: var(--color-tertiary-grey-mid, #787D8C);
      
       
        font-size: 16px;
      
        font-weight: 400;
        line-height: 150%;}

        .span_text{ color: var(--color-tertiary-grey-light, #B4B9C8);
       margin-bottom: 80px;
          
            font-size: 14px;
           
            font-weight: 400;
            line-height: 150%; }

.span_text_logo img{ margin-left: 8px;}    
.overall_property_detail h3{ color: var(--color-secondary-dark, #141928);

   
    font-size: 20px;
  
    font-weight: 700;
    }        

    .overall_facts_features {
        justify-content: space-between;
        margin-top: 23px;
    }
    .full_left_hand { border-bottom: 2px solid #B4B9C8; justify-content: space-between !important;
        padding: 24px 0px;
        padding-bottom: 8px;}
    .left_hand {
        width: 48%;
    }
    .update_detail_left{ color: var(--color-tertiary-grey-mid, #787D8C);
      
      
        font-size: 16px;
     
        font-weight: 400;
        line-height: 150%; }
    .update_detail_right{ color: var(--color-secondary-dark-light, #505564);
        text-align: right;
       
        font-family: Roboto;
        font-size: 16px;
        
        font-weight: 700;
       }

       .features_all {
        margin-top: 110px;
    }
       .map_area{ margin-top: 120px;}
       .write_location{ margin-top: 30px;}
       .location_control{ background: #F0F5FF !important; height: 64px; color: var(--color-secondary-dark-light, #505564);
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; border-radius: 0px !important; border: none !important; padding-left: 70px !important;}
        .write_location{ position: relative;}
        .icon_locate {
            position: absolute;
            top: 14px;
            left: 13px;
        }
       

        .property-detail-form{ position: -webkit-sticky; 
            position: sticky;
            top: 50px;
            z-index: 10;
            transform: scale(0.98);
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease, box-shadow 0.3s ease; }
            .full-white-block {
                border-radius: 5px;
                background: var(--color-white-pure, #FFF);
                box-shadow: 0px 0px 20px 0px rgba(231, 231, 231, 0.25);
                padding: 20px;
                padding-bottom: 10px;
                margin-top: 30px;
            }
            
    .all_points{ justify-content: space-between;}
    .left_points {
        width: 49%;
        margin-top: 20px;
    }

    .left_points p{ color: var(--color-secondary-dark-light, #505564);
        
       
        font-size: 14px;
      
        font-weight: 400;
        line-height: 150%; background: url(../../public/dot.png) no-repeat;  background-position: 0px 8px;
        padding-left: 13px;
        margin-top: -10px;}
        .mt50{ margin-top: 120px;}

        .listingdetail{ margin-top: 100px; margin-bottom: 100px;}
        .listingdetail p {
            color: var(--color-tertiary-grey-light, #B4B9C8);
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
            margin-top: -12px;
        }

        .overall_schools h4 {
            color: var(--color-tertiary-grey-mid, #787D8C);
            font-family: 'Oxygen', sans-serif !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            margin-top: 20px;
        }
        .overall_schools p{ color: var(--color-tertiary-grey-mid, #787D8C);
            
            font-size: 16px;
           
            font-weight: 400;
            line-height: 150%;  }

            .school_code{ color: var(--color-tertiary-grey-mid, #787D8C);
              
                font-size: 12px;
              
                font-weight: 400;
                line-height: 150%; }

                .bottom_data p{ color: var(--color-tertiary-grey-mid, #787D8C);
                   
                    font-size: 12px;
                   
                    font-weight: 400;
                    line-height: 150%;  background: url(../../public/dot-grey.png) no-repeat; background-position: 0px 5px;
                    padding-left: 11px;
                    margin-left: 9px;
                }

                .schools .update_detail_right {
                    text-align: left;
                    margin-left: 20px;
                }

                .schools .update_detail_left{ color: var(--color-secondary-dark-light, #505564);

                  
                    font-family: Roboto;
                    font-size: 16px;
                  
                    font-weight: 700;
                  }

                  .schools .full_left_hand {
                    justify-content: inherit !important;
                    padding-bottom: 0px;
                    vertical-align: middle;
                    height: 75px;
                    margin-top: 22px;
                }

                .listed_by_detail{ margin-top: 80px;}


.first-row {
  display: flex;
align-items: center;
 vertical-align: middle;
margin-top: 40px;
 }

.round-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;  
}

.text-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px; 
}


.second-row textarea {
    width: 100%;
    min-height: 215px;
    padding: 25px;
    resize: vertical;
    border-radius: 10px;
    margin-top: 27px;
    border: 1px solid var(--color-tertiary-grey-light, #B4B9C8);
    color: var(--color-tertiary-grey-light, #B4B9C8) !important; background: transparent;
}


.third-row {
    display: flex;
    justify-content: space-between;
    align-items: center;  
}
.ask_ques{ padding: 13px 35px;}
.overall_call{ color: var(--color-tertiary-grey, #646978);
   
    
    font-size: 16px;
  
    font-weight: 400;
    line-height: 150%; }

    .phone_update {
        color: var(--color-secondary-dark-light, #505564);
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        margin-left: 6px;
    }


 
.market-report .nav-tabs {
    border-bottom: none; 
    background-color: white; 
}


.market-report .nav-link.custom-active {
    color: white;
    background-color: #22A9E0;
    border: none;
    border-radius: 0; 
}


.market-report .nav-link.custom-inactive {
    color: #22A9E0;
    background-color: transparent;
    border: none;
    border-radius: 0; 
}


.market-report #myTab {
    border-bottom: 0px;
    width: 76.1% !important;
    padding-right: 0px !important;
    margin: 0px;
}
.market-report .tab-content {
    border-top: none;
    padding: 0px;
    margin-top: 50px;
}

.market-report .nav-link { padding: 10px 35px !important; color: #22A9E0;
   
  
   cursor: pointer;
    font-size: 14px !important;
   
    font-weight: 400 !important;
   }

   tspan {
    color: var(--color-tertiary-grey, #646978);


font-size: 12px !important;

font-weight: 400;
line-height: 150%; 
}

.price-info {
    display: flex;
    justify-content: space-between; margin-bottom: 70px;
}

.price-column {
    flex: 1;
   
    padding: 0 15px;
}

.price-column h4{color: var(--color-secondary-dark, #141928);

    font-size: 24px;
  
    font-weight: 700;
   }
.price-column p{ color: var(--color-tertiary-grey, #646978);
   
   
    font-size: 14px;
  
    font-weight: 400;
    line-height: 150%; }




    .dropdown-wrapper {
        position: relative; margin-bottom: 40px;
    }
    
    .custom-dropdown {
        border: none;
        outline: none;
        border-radius: 0;
        background-color: transparent; color: var(--color-secondary-dark-light, #505564);
       
        font-family: Roboto;
        font-size: 18px;
      
        font-weight: 700;
       
        
       
    }
    
  
    .property-detail {
        font-family: 'Arial', sans-serif;
    }
    
    .header-section {
        display: flex;
        justify-content: space-between;
        background-color: white;
        padding: 9px 0;
        box-shadow: none;
        margin-top: 50px; border-radius: 5px;
    }
    .header-column {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        padding: 0 20px;
    }
    
    .header-top, .header-bottom {
        margin: 0;
    }
    
    .data-section {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        border-bottom: 1px solid #eaf0fc;
    }
    .data-column {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: left;
        padding: 0 20px;
    }
    .header-top{ color: var(--color-secondary-dark-light, #505564) !important;
       
        font-size: 14px !important;
       
        font-weight: 400 !important;
        line-height: 150%; }

        .header-bottom{ color: var(--color-secondary-dark-light, #505564) !important;
          
           
            font-size: 14px !important;
            
            font-weight: 400 !important;
            line-height: 150%; }
    
            .data-column p{ color: var(--color-secondary-dark-light, #505564);
             
                font-family: Roboto;
                font-size: 16px;
              
                font-weight: 700;
              }


              .firstp {
                background-image: url('../../public/pin-point.png');
                background-repeat: no-repeat; padding-left: 28px;
                
            }
            




            @media (min-width: 992px) and (max-width: 1279px) {
                .custom-footer {
                    padding: 30px 0px;
                    margin-top: 50px !important;
                }


                .price-info {
                   
                    margin-bottom: 50px;
                }

                .market-report .nav-link {
                    padding: 10px 34px !important;
                   
                }



                .prop_detail_form {
                   
                    margin-top: 10px;
                }
                .market-report #myTab {
                  
                    width: 100% !important;
                  
                }
                .bottom_calc .font-weight-bold{ font-size: 20px !important;}
                .top_calc {
                    padding: 10px 3px;
                }
                .bottom_data p {
                    font-size: 12px;
                   width: 100%;
                    background-position: 0px 7px;
                    padding-left: 11px;
                    margin-left: 0px;
                    margin-bottom: 5px;
                }


.school_code{ width: 100%;}

                .schools .full_left_hand {
                   
                    height: inherit
                    
                }

.bottom_data { flex-wrap: wrap;}
                
                .listingdetail {
                    margin-top: 50px;
                    margin-bottom: 50px;
                   
                }
                p{ font-size: 14px !important;}
                .mt50 {
                    margin-top: 50px;
                }
                .features_all {
                    margin-top: 50px;
                }
                .map_area {
                    margin-top: 50px;
                }
                .update_detail_right {
                    
                    font-size: 14px;
                    
                }

                .update_detail_left {
                   
                    font-size: 14px;
                   
                }

                .btn_blue {
                  
                    padding: 14px 10px;
                   
                    font-size: 15px;
                   
                }
                .set_widthe {
                    width: 42% !important;
                }
                .set_widthes{ width: 56% !important;}
                h2{ font-size: 19px !important;}

                .Property_detailing .price {
                    
                    font-size: 32px;
                   
                    margin-top: 50px;
                }
                .three_btns {
                   
                    gap: 20px;
                    
                }

            }



            @media (min-width: 768px) and (max-width: 991px) {

                .bottom_calc {
                    background: #fff;
                    padding: 20px;
                    margin-top: 15px;
                    padding-top: 0px;
                    padding-bottom: 22px;
                    border-radius: 5px;
                }
            
            
                .huf{ margin-top: -10px !important;}
                .tt{ margin-top: 20px;}
            
                .bottom_calc .font-weight-bold {
                   
                    font-size: 16px !important;
                  
                }
                .custom-footer {
                  
                    margin-top: 50px !important;
                }
            
                .price-column {
                    flex: inherit;
                    padding: 0 15px;
                    width: 100%;
                }
                .price-info {
                   
                    margin-bottom: 20px;
                    flex-wrap: wrap;
                }
            
                .market-report #myTab {
                   
                    width: 100% !important;
                   
                }
            
            
                .listed_by_detail li.nav-item {
                    width: 100%;
                }
            
            
                .listed_by_detail {
                    margin-top: 30px;
                }
                .third-row {
                  
                    flex-wrap: wrap;
                    gap: 20px;
                }
               
            
            
                .icon-placeholder img{ width: 35px;}
            
                .calculation_zone {
                 
                    padding: 15px;
                   
                    padding-top: 0px;
                }
            
                .dpay_amount {
                   
                    top: 64px !important;
                    
                    font-size: 14px !important;
                    
                }
                .ppt {
                    margin-bottom: -21px !important;
                }
                .calculation_zone label {
                   
                   
                   
                    margin-top: 22px;
                }
            
                .top_calc .form-control {
                   
                    font-size: 14px;
                }
            
            .calculation_zone label {
               
                font-size: 14px !important;
                
            }
                .top_calc {
                    padding: 0px 0px !important;
                }
            
                .bottom_data p{ background-position: 0px 9px;}
            
                .overall_facts_features .update_detail_right {
                    width: 78%;
                }
            
                .schools .update_detail_left {
                   
                    font-size: 14px; width: 20%;
                   
                }
            
                .overall_facts_features {
                  
                    margin-top: -7px;
                }
            
                .full_left_hand {
                   
                    padding: 12px 0px;
                    
                }
            
            
            
                .Property_detailing .amenities {
                   
                    margin-top: 30px;
                }
            
                .Property_detailing .single_amenities {
                    margin-top: -8px !important;
                }
            
                .Property_detailing .amenities {
                    gap: 15px;
                    flex-wrap: wrap;
                }
            
                .btn_blue {
                    font-size: 12px;
                    padding: 10px 8px;
                }
                .three_btns {
                   
                    gap: 5px;
                  
                }
            
            
                .detail_icon {
                   
                    font-size: 14px;
                  
                }
            
            
            
                .stars {
                    color: #22A9E0;
                    font-size: 12px;
                }
                .avm {
                   
                    font-size: 14px;
                    
                    margin-top: 5px;
                }
            
            
            
                .rate_number, .total_rate {
                    
                    font-size: 12px;
                   
                }
            
            
            
                .Property_detailing .price-amenities {
                    margin-top: -8px;
                   
                }
            
                .propert-banner-bg {
                   
                    padding: 16px 0px;
                    
                }
            
            
            
                .btn_setting {
                   
                    margin-top: 15px;
                }
                .Property_detailing h2 {
                   
                    font-size: 20px !important;
                    
                }
                .Property_detailing .location_name {
                    font-size: 15px;
                    margin-top: 9px;
                }
                .Property_detailing .price {
                    font-size: 23px;
                    margin-top: 25px;
                }
                .mt-new {
                    margin-top: 20px;
                }
                .prop_detail_form {
                    padding: 30px 0px;
                    margin-top: 0px;
                }
                .overall_property_detail h2 {
                    font-size: 20px !important;
                }
                .overall_property_detail p {
                   
                    line-height: 25px;
                }
                .span_text {
                   
                    margin-bottom: 50px;
                    
                }
                .update_detail_left {
                   
                    width: 100%;
                }
                .update_detail_right{  width: 52%;}
                .left_hand {
                    width: 48%;
                }
                .overall_facts_features {
                   flex-wrap: wrap;
                }
                .update_detail_right {
                    font-size: 13px;
                }
                .update_detail_left {
                    font-size: 13px;
                }
                .features_all {
                    margin-top: 26px;
                }
                .map_area {
                    margin-top: 50px;
                }
                input.form-control.location_control {
                    font-size: 11px;
                    padding-left: 54px !important;
                }
                .mt50 {
                    margin-top: 40px;
                }
                .all_points {
                   flex-wrap: wrap; margin-top: 30px;
                }
                .left_points{ width: 100%;}
                .left_points {
                    
                    margin-top: 0px;
                }
                .listingdetail {
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .school_code{ width: 100%;}
                .bottom_data{ width: 100%;}
                .bottom_data p{ width: 100%;}
                .bottom_data{ flex-wrap: wrap;}
                .schools .full_left_hand {
                    height: 140px;
                    margin-top: 9px;
                }
                .update_detail_left {
                    width: 48%;
                }
                .bottom_data p {
                    margin-left: 0px;
                    margin-top: -20px;
                }
                .school_code {
                    margin-top: 5px;
                    margin-bottom: 30px;
                }
                .calculation_zone {
                    
                    margin-top: 36px;
                }
            
            }
            



@media (min-width: 320px) and (max-width: 767px) {

    .bottom_calc {
        background: #fff;
        padding: 20px;
        margin-top: 15px;
        padding-top: 0px;
        padding-bottom: 22px;
        border-radius: 5px;
    }


    .huf{ margin-top: -10px !important;}
    .tt{ margin-top: 20px;}

    .bottom_calc .font-weight-bold {
       
        font-size: 16px !important;
      
    }
    .custom-footer {
      
        margin-top: 50px !important;
    }

    .price-column {
        flex: inherit;
        padding: 0 15px;
        width: 100%;
    }
    .price-info {
       
        margin-bottom: 20px;
        flex-wrap: wrap;
    }

    .market-report #myTab {
       
        width: 100% !important;
       
    }


    .listed_by_detail li.nav-item {
        width: 100%;
    }


    .listed_by_detail {
        margin-top: 30px;
    }
    .third-row {
      
        flex-wrap: wrap;
        gap: 20px;
    }
   


    .icon-placeholder img{ width: 35px;}

    .calculation_zone {
     
        padding: 15px;
       
        padding-top: 0px;
    }

    .dpay_amount {
       
        top: 64px !important;
        
        font-size: 14px !important;
        
    }
    .ppt {
        margin-bottom: -21px !important;
    }
    .calculation_zone label {
       
       
       
        margin-top: 22px;
    }

    .top_calc .form-control {
       
        font-size: 14px;
    }

.calculation_zone label {
   
    font-size: 14px !important;
    
}
    .top_calc {
        padding: 0px 0px !important;
    }

    .bottom_data p{ background-position: 0px 9px;}

    .overall_facts_features .update_detail_right {
        width: 78%;
    }

    .schools .update_detail_left {
       
        font-size: 14px; width: 20%;
       
    }

    .overall_facts_features {
      
        margin-top: -7px;
    }

    .full_left_hand {
       
        padding: 12px 0px;
        
    }



    .Property_detailing .amenities {
       
        margin-top: 30px;
    }

    .Property_detailing .single_amenities {
        margin-top: -8px !important;
    }

    .Property_detailing .amenities {
        gap: 15px;
        flex-wrap: wrap;
    }

    .btn_blue {
        font-size: 12px;
        padding: 10px 8px;
    }
    .three_btns {
       
        gap: 5px;
      
    }


    .detail_icon {
       
        font-size: 14px;
      
    }



    .stars {
        color: #22A9E0;
        font-size: 12px;
    }
    .avm {
       
        font-size: 14px;
        
        margin-top: 5px;
    }



    .rate_number, .total_rate {
        
        font-size: 12px;
       
    }



    .Property_detailing .price-amenities {
        margin-top: -8px;
       
    }

    .propert-banner-bg {
       
        padding: 16px 0px;
        
    }



    .btn_setting {
       
        margin-top: 15px;
    }
    .Property_detailing h2 {
       
        font-size: 20px !important;
        
    }
    .Property_detailing .location_name {
        font-size: 15px;
        margin-top: 9px;
    }
    .Property_detailing .price {
        font-size: 23px;
        margin-top: 25px;
    }
    .mt-new {
        margin-top: 20px;
    }
    .prop_detail_form {
        padding: 30px 0px;
        margin-top: 0px;
    }
    .overall_property_detail h2 {
        font-size: 20px !important;
    }
    .overall_property_detail p {
       
        line-height: 25px;
    }
    .span_text {
       
        margin-bottom: 50px;
        
    }
    .update_detail_left {
       
        width: 100%;
    }
    .update_detail_right{  width: 52%;}
    .left_hand {
        width: 100%;
    }
    .overall_facts_features {
       flex-wrap: wrap;
    }
    .update_detail_right {
        font-size: 13px;
    }
    .update_detail_left {
        font-size: 13px;
    }
    .features_all {
        margin-top: 26px;
    }
    .map_area {
        margin-top: 50px;
    }
    input.form-control.location_control {
        font-size: 11px;
        padding-left: 54px !important;
    }
    .mt50 {
        margin-top: 40px;
    }
    .all_points {
       flex-wrap: wrap; margin-top: 30px;
    }
    .left_points{ width: 100%;}
    .left_points {
        
        margin-top: 0px;
    }
    .listingdetail {
        margin-top: 60px;
        margin-bottom: 40px;
    }
    .school_code{ width: 100%;}
    .bottom_data{ width: 100%;}
    .bottom_data p{ width: 100%;}
    .bottom_data{ flex-wrap: wrap;}
    .schools .full_left_hand {
        height: 140px;
        margin-top: -11px;
    }
    .update_detail_left {
        width: 48%;
    }
    .bottom_data p {
        margin-left: 0px;
        margin-top: -20px;
    }
    .school_code {
        margin-top: 5px;
        margin-bottom: 30px;
    }
    .calculation_zone {
        
        margin-top: 36px;
    }

}



select {
    --bs-form-select-bg-img: url('../../public/assets/arow.svg') !important;
    background-size: 20px 20px !important;  /* Adjust the width and height values as per your requirement */
    background-repeat: no-repeat !important;
    background-position: right 1rem center !important;  /* Adjust positioning if needed */ 
    cursor: pointer;
  }

  .form-select:focus{ border-color: none !important; 
    outline: none !important;
     box-shadow: none !important;}

     .form-control:focus{ border-color: none !important; 
        outline: none !important;
         box-shadow: none !important;}