.search-property{ box-shadow: 0px 0px 20px 0px rgba(20, 25, 40, 0.20); padding: 30px; padding-bottom: 10px; border-radius: 10px; margin-top: -89px !important; background: #fff;}
.search-property .form-control, .search-property .form-select{ height: 42px; border: 1px solid #F0F5FF; border-left: 0px; color: #646978; font-size: 14px; padding-left: 4px;}
.search-property span.input-group-text {background: none;border: 1px solid #F0F5FF; color: #646978;border-right: 0px; font-weight: 400;  }


.custom_search{ border-radius: 4px;
    background: #22A9E0 !important;
    box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50); font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; border:none !important ; height: 42px; font-weight: 500;}

    a.btn.btn-primary.w-100.custom_search {
        padding-top: 10px;
    }
    

    