.full_map_area {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -63px;
}
.left_map_area{ width: 98.5%;}
.right_property_listing{ display: none;}

.full_map_mark{}


.right_property_listing.map_only {
    height: 800px;
    overflow-y: scroll;
    overflow-x: hidden; padding-right:10px;
}
.left_map_area{ position: relative;}
.map_arrow {
    position: absolute;
    top: 50px;
    right: -20px;
    cursor: pointer;
    z-index: 9999;
}



.left_map_area.collapsed {
    width: 65%;
    transition: width 0.3s; 
}

.right_property_listing.expanded {
    width: 30%;
    margin-right: 2%;
    display: block; 
    transition: width 0.3s, margin-right 0.3s;
}



@media (min-width: 768px) and (max-width: 1145px) {

    
    .map_arrow {
       
        right: 7px;
        
    }

    .map_arrow img{ height: 30px; width: 30px;}
    .left_map_area.collapsed {
        width: 44%;
        
    }
    .right_property_listing.expanded {
        width: 50%;
        
    }
    .full_map_mark .left-overlays {
        width: 50%;
    }
    .left_map_area.collapsed .map_arrow {
       
        right: -11px; top: 100px;
        
    }

}


@media (min-width: 320px) and (max-width: 767px) {

    
    .map_arrow {
       
        right: 7px;
        
    }

    .map_arrow img{ height: 30px; width: 30px;}
    .left_map_area.collapsed {
        width: 0%;
        
    }
    .right_property_listing.expanded {
        width: 95%;
        
    }
    .full_map_mark .left-overlays {
        width: 50%;
    }
    .left_map_area.collapsed .map_arrow {
       
        right: -31px; top: 100px;
        
    }

}





