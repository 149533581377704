.agent_list{ margin-top: 100px;}

.agent_list h2 {
    text-align: center;
    margin-bottom: 55px;
}
.overall_agents{ justify-content: space-between;}
.agent_one{ width: 23%; background: #fff; text-align: center; padding: 40px;}
.agent_one h3 {
    color: #22A9E0;
    text-align: center;
    font-family: Oxygen !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-top: 32px;
}
.agent_one h4{ color: var(--color-secondary-dark, #141928);
    text-align: center;
  
    font-family: Oxygen !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; }
    .agent_one p {
        color: var(--color-tertiary-grey-mid, #787D8C);
        text-align: center;
        font-family: Oxygen;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 21px;
    }

    .view_agent {
        color: #22A9E0;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: none;
        border: none;
        margin-top: 25px;
    }