.testimonials{ margin-top: 100px; margin-bottom: 50px;}
.testimonials_heading{ text-align: center;}
.testimonials_heading p{ 
    font-size: 16px;
    color: var(--color-tertiary-grey-mid, #787D8C);

    font-weight: 400;
    line-height: 150%; padding: 0px 250px;}







    .testimonials {
        padding: 50px 0;
        background-color: #f9f9f9;
    }
    
    .testimonials_heading {
        text-align: center;
        margin-bottom: 50px;
    }
    
    .testimonial {
        background-color: #ffffff;
        border-radius: 10px;
        padding: 45px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;
        margin-bottom: 22px; min-height: 265px;
    }
    
    .testimonial:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
    }
    
    .testimonial-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    
    .image-container {
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        margin-right: 20px;
        border-radius: 50%;
        overflow: hidden;
    }
    
    .image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .info .name {
        font-size: 18px;
        
        margin-bottom: 5px;

        color: var(--color-secondary-dark, #141928);
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
    }
    
    .info .designation {
        font-size: 14px;
        color: #777777;
        color: var(--color-tertiary-grey-mid, #787D8C);



font-weight: 400;
line-height: 150%; 
    }
    
    .testimonial-text {
        margin-bottom: 20px; color: var(--color-tertiary-grey-mid, #787D8C);
       
       
        font-size: 14px;
       
        font-weight: 400;
        line-height: 150%; 
    }
    
    .rating {
        color: #22A9E0;
    }
    
    .rating .fa-star {
        margin-right: 5px;
    }
    