

.property_drop {
  position: absolute;
  border-radius: 10px;
  background: var(--color-white-pure, #FFF);
  box-shadow: 0px 8px 10px 0px rgba(20, 25, 40, 0.25);
  min-width: 468px;
  top: 42px;
  left: -50%;
  z-index: 9999; padding: 20px;
}
.option {
  display: inline-block;
 font-size: 14px;
  margin: 10px;
  border-radius: 50px;
border: 1px solid var(--color-tertiary-grey, #646978); padding: 5px 20px;
  
}

.option.selected {
  background-color: #22A9E0 ; color: #fff;
}
.two_input_blocks{ flex-wrap: wrap; justify-content: space-between;}
.left_input_blocks{ position: relative; width: 48%; }
.right_input_blocks{ position: relative;  width: 48%;}
.two_input_blocks .form-control {
  border-left: 1px solid #F0F5FF;
  padding-left: 44px;
}
.icon_feature {
  position: absolute;
  top: 9px;
  left: 9px;
}

.square_ft .form-control {
  padding-left: 19px; 
}
.features_list h3 {
  color: var(--color-secondary-dark, #141928);
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 150%;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}
.acre_swap{ justify-content: space-between; align-items: center;}




/* checkbox */

.unique-radio-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 27px;
}

.unique-radio-label {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid #00aaff;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  margin-left: 40px;
}

.unique-radio-label::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #00aaff;
  opacity: 0;
}

input[type="radio"]:checked + .unique-radio-label::after {
  opacity: 1;
}

input[type="radio"] {
  display: none;
}
.right_swap span {
  margin-left: 24px;
 
  position: relative;
  top: -3px;
}

