.mutual_popuus .modal{ --bs-modal-width: 900px !important;}

.advance_search_done{ padding: 20px;}

.advance_search_done h2{ color: var(--color-secondary-dark, #141928) !important;
   margin: 0 auto;
    
  
    font-size: 32px !important;
   
    font-weight: 700 ;
  }



   
   
    

    @media (min-width:320px) and (max-width: 1024px) {

     

        }


    