.blog_posts{flex-wrap: wrap;  gap: 35px;}

.single_blog_block {
    width: 31.5%;
    border-radius: 0px 0px 10px 10px;
    background: var(--color-white-pure, #FFF);
}

    .single_blog_block img{ border-radius: 10px; width: 100%;}

    .single_blog_block h2{color: var(--color-secondary-dark, #141928) !important;

       
        font-size: 24px !important;
     
        font-weight: 700 !important; padding: 15px; padding-bottom: 10px;
       }
    .single_blog_block p{ color: var(--color-tertiary-grey-mid, #787D8C);

       
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 150%; padding: 15px; padding-top: 0px; }

        .blogs_all h1{ padding: 70px 0px; padding-bottom: 0px;}

        .blogs {
            justify-content: space-between; margin-bottom: 50px; flex-wrap: wrap;
        }

        .blogs .agents_count {
            margin-top: 72px;
        }

        .pagination span{ font-size: 25px;}

        /* single-blog */

        .blog_banner img{ width: 100%;}
        .single_data{ background: var(--color-white-pure, #FFF);
            box-shadow: 0px 0px 20px 12px rgba(190, 190, 190, 0.20); padding: 50px; margin-top: -120px;}

            .single_data h1{ color: var(--color-secondary-dark, #141928);
                text-align: center;
                padding: 20px;
              
                font-size: 48px;
               
                font-weight: 700;
                }
            .single_data h2{ color: var(--color-secondary-dark-light, #505564);

               
                font-size: 24px !important; margin-top: 32px; margin-bottom: 16px;
               
                font-weight: 700;
              }
            .single_data p{ color: var(--color-tertiary-grey-mid, #787D8C);

              
                font-size: 16px;
              
                font-weight: 400;
                line-height: 150%; }

                .date_time{ text-align: right; margin-bottom: 32px; color: var(--color-tertiary-grey, #646978);

                
                    font-size: 16px;
                  
                    font-weight: 400;
                    line-height: 150%; /* 24px */}

                    .date_time img{ position: relative; top: -2px;}

                    

                    .previous_blog a{ color: var(--color-tertiary-grey-mid, #787D8C);

                       
                        font-size: 20px;
                       
                        font-weight: 700;
                       }

                        .previous_next { flex-wrap: wrap; justify-content: space-between; margin-top: 70px;}

                        .top_blog {
                            color: var(--color-tertiary-grey-mid, #787D8C);
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 150%;
                        }
                        .bottom_blog {
                            width: 85%;
                            margin-top: 15px;
                        }
                        
                        .top_blog span {
                            position: relative;
                            display: inline-block;
                            height: 64px;
                            width: 64px;
                            text-align: center;
                            padding: 8px;
                            z-index: 1; cursor: pointer;
                        }
                        
                        .top_blog span::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: #F0F5FF;
                            border-radius: 500px;
                            filter: blur(5px);
                            z-index: -1; 
                        }
                        

                        .previous_blog span {
                            margin-left: 15px;
                            margin-right: 15px;
                        }

                        .previous_blog span{ margin-left: 0px !important;}



@media (min-width:1025px) and (max-width: 1399px) {



    .top_blog span {
       
        height: 54px;
        width: 55px;
       
    }

    .single_blog_block h2 {
      
        font-size: 20px !important;
       
    }

    .blogs_all h1{ padding: 80px 0px;} 
    .blogs_all h1 {
        
        margin-bottom: -40px; font-size: 50px !important;
    }
    .single_blog_block {
        width: 30.9%;
    }
}                        

@media (min-width:768px) and (max-width: 1024px) {
    .top_blog {
     
        font-size: 16px;
       
    }
    .bottom_blog {
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
    }

    .previous_next {
       
        width: 100%;
    }


    .bottom_blog {
        width: 100%;
        margin-top: 15px;
    }

    .single_data h1 {
        
        font-size: 30px;
        
    }



    .single_blog_block {
        width: 47.6%;
        
    }

    .blogs_all h1{ padding: 30px 0px;} 
    .blogs_all h1 {
        padding: 30px 0px;
        margin-bottom: -40px;
    }
}                        


 @media (min-width: 320px) and (max-width: 767px) {

    .previous_blog.next_blog {
        margin-top: 30px;
    }
    .bottom_blog {
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
    }
   
    .top_blog {
       
        font-size: 15px;
       
    }
    .top_blog span {
       
        height: 40px;
        width: 40px;
        
        padding: 4px;
       
    }
    .single_data h1 {
       
        padding: 8px;
        font-size: 22px !important;
        
    }
    .date_time {
      
        font-size: 13px;
       
    }
    .single_data h2 {
       
        font-size: 20px !important;
        
    }



    .single_data {
       
        padding: 10px;
        margin-top: -30px;
    }


    .blogs {
      
        margin-bottom: 22px;
        
    }
    .blogs_all h1 {
        padding: 30px 0px; margin-bottom: 0px !important;
        
    }
    .pagination {
        
        margin-top: 0px; margin-top: 30px;
    }
    .blogs .agents_count {
        margin-top: 32px;
        font-size: 15px;
    }
    .Second_coint{ display: none;}
    .agents_count{ width: 100%;}
    .single_blog_block{ width: 100%;}
    .single_blog_block h2 {
      
        font-size: 20px !important;
        
    }
    .custom-footer {
        padding: 20px;
        background-color: #141928;
        margin-top: 120px;
        padding: 20px 0px;
        padding-bottom: 30px;
    }

 }